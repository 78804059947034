import React from "react"

const DirectoryCategories = () => (
  <div>
    <div className="row">
      <div className="col-md-4">
        <ul>
          <li>Adtech &amp; Networks</li>
          <li>Apps</li>
          <li>App Stores</li>
          <li>Backup and Sync</li>
          <li>Banking</li>
          <li>Betting</li>
          <li>Browsers</li>
          <li>Cloud Computing</li>
          <li>Collaboration</li>
          <li>Communication</li>
          <li>Cryptocurrencies</li>
          <li>Developer</li>
          <li>Domains</li>
          <li>Education</li>
          <li>Email</li>
        </ul>
      </div>
      <div className="col-md-4">
        <ul>
          <li>Entertainment</li>
          <li>Finance</li>
          <li>Food</li>
          <li>Gaming</li>
          <li>Government</li>
          <li>Health</li>
          <li>Hosting/VPS</li>
          <li>Hotels and Accommodations</li>
          <li>Identity Management</li>
          <li>Investing</li>
          <li>IoT</li>
          <li>ISPs</li>
          <li>Legal</li>
          <li>Mobile</li>
          <li>Marketplaces</li>
        </ul>
      </div>

      <div className="col-md-4">
        <ul>
          <li>Operating Systems</li>
          <li>Media </li>
          <li>Payments</li>
          <li>Remote Access</li>
          <li>Retail</li>
          <li>Software</li>
          <li>SaaS</li>
          <li>Security</li>
          <li>Social Media</li>
          <li>Task Management</li>
          <li>Texting &amp; SMS</li>
          <li>Telephony</li>
          <li>Transport</li>
          <li>Utilities</li>
          <li>VPN Providers</li>
          <li>Video Conferencing</li>
          <li>Other</li>
        </ul>
      </div>
    </div>
  </div>
)
export default DirectoryCategories
